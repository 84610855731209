import React from "react";
import Layout from "../components/layout";

const Klienci = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Klienci",
        href: pathname,
        lang: "pl",
      }}
    >
      <section className="container-fluid home_section_3">
        <div className="text-center">
          <h2>Klienci</h2>
        </div>
        <div className="client_grid">
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_30.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_2.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_3.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_4.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_17.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_16.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_7.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_8.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_9.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_10.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_11.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_12.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_13.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_6.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_14.png")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_15.png")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_18.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_19.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_20.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_21.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_22.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_23.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_24.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_25.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className="row image_row">
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_26.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_27.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_28.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-3">
              <img
                src={require("../assets/img/cooperation/cooperation_logo_29.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Klienci;
